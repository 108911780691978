import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'
import eleven from '../assets/svg/projects/eleven.svg'
import thefanss from '../assets/svg/projects/thefanss.png'
import tunesick from '../assets/svg/projects/tunesick.png'
import onPsych from '../assets/svg/projects/onPsych.png'
import compnet from '../assets/svg/projects/compnet.png'
import jinji from '../assets/svg/projects/jinji.png'
import highway from '../assets/svg/projects/highway.png'
import onpyschNdis from '../assets/svg/projects/onpyschNdis.png'
import cib from '../assets/svg/projects/cib.png'
import acjc from '../assets/svg/projects/acjc.png'
import austit from '../assets/svg/projects/austit.png'
import phu from '../assets/svg/projects/phu.png'
import dni from '../assets/svg/projects/dni.png'
import solution from '../assets/svg/projects/solution.png'
import thesbr from '../assets/svg/projects/thesbr.png'
import ondeemand from '../assets/svg/projects/ondeemand.png'
import ainesheba from '../assets/svg/projects/ainesheba.png'
import holister from '../assets/svg/projects/holister.png'
import chl from '../assets/svg/projects/chl.png'
import OCTAGON from '../assets/svg/projects/OCTAGON.png'
import family from '../assets/svg/projects/family.png'
import Harvard from '../assets/svg/projects/Harvard.png'
import indoamazonacai from '../assets/svg/projects/indoamazonacai.svg'
import theacaishop from '../assets/svg/projects/theacaishop.png'
import Hyhfusa from '../assets/svg/projects/HYHF.png'
import aurorahardwood from '../assets/svg/projects/aurorahardwood.png'
import creditforretention from '../assets/svg/projects/creditforretention.png'
import quotensave from '../assets/svg/projects/quotensave.png'
import kaypros from '../assets/svg/projects/kaypros.jpg'
import kayheatingcooling from '../assets/svg/projects/kayheatingcooling.jpg'
import peterlionsford from '../assets/svg/projects/Peter.png'
import hume from '../assets/svg/projects/hume.png'
import artik from '../assets/svg/projects/Artik.webp'
import artizia from '../assets/svg/projects/artizia.png'


export const projectsData = [
    {
        id: 1,
        projectName: 'Tunesick App',
        projectDesc: 'TuneSick is a complete all in one audio streaming and sharing platform. ',
        tags:  ['PHP','Javascript','Jquery','Ajax', 'HTML', 'CSS'],
        code: 'https://www.tunesick.app/',
        demo: 'https://www.tunesick.app/',
        image: tunesick
    },
    {
        id: 2,
        projectName: 'The Fanss',
        projectDesc: 'This project aims to build an multi vendor E-commerce system',
        tags: ['Laravel','Javascript','Jquery','Ajax', 'HTML', 'CSS','Bootstrap','Flutter'],
        code: 'https://thefanss.com/',
        demo: 'https://thefanss.com/',
        image: thefanss
    },
    {
        id: 3,
        projectName: 'Indoamazonacai',
        projectDesc: 'Organic Acai Berry Supplier',
        tags:  ['WordPress'],
        code: 'https://indoamazonacai.com/',
        demo: 'https://indoamazonacai.com/',
        image: indoamazonacai
    },
    {
        id: 4,
        projectName: 'The Acai Shop',
        projectDesc: 'The Acai Shop provide high nutritional benefits to South East Asia with our Amazon Forest smoothie bowls and juices!',
        tags:  ['WordPress'],
        code: 'https://theacaishop.com/',
        demo: 'https://theacaishop.com/',
        image: theacaishop
    },
    {
        id: 30,
        projectName: 'Holister BD(url not provided due to client policy)',
        projectDesc: 'Holister is a logistics company that aims to aid Bangladesh’s expanding e-commerce sector by providing tech-first courier service and parcel delivery',
        tags: ['PHP','HTML', 'CSS','Bootstrap','Javascript','Jquery','Ajax'],
        code: '',
        demo: '',
        image: holister
    },
    {
        id: 31,
        projectName: 'CHL(url not provided due to client policy)',
        projectDesc: 'Internal company maintenance',
        tags: ['Laravel','Javascript','Jquery','Ajax', 'HTML', 'CSS','Bootstrap'],
        code: '',
        demo: '',
        image: chl
    },
    {
        id: 32,
        projectName: 'Onpsych Medicare(url not provided due to client policy)',
        projectDesc: 'Doctor and patient billing system using Medicare(Medicare is the publicly-funded universal health care insurance scheme in Australia)',
        tags: ['CodeIgniter','Javascript','Jquery','Ajax', 'HTML', 'CSS','Bootstrap'],
        code: '',
        demo: '',
        image: onPsych
    },
    {
        id: 33,
        projectName: 'Octagon POS(url not provided due to client policy)',
        projectDesc: 'Basic POS Software for client',
        tags: ['Laravel','Javascript','Jquery','Ajax', 'HTML', 'CSS','Bootstrap'],
        code: '',
        demo: '',
        image: OCTAGON
    },
    {
        id: 34,
        projectName: 'FamilyBD POS(url not provided due to client policy)',
        projectDesc: 'Basic POS Software for client',
        tags: ['Laravel','Javascript','Jquery','Ajax', 'HTML', 'CSS','Bootstrap'],
        code: '',
        demo: '',
        image: family
    },
    {
        id: 35,
        projectName: 'Harvard School Software(url not provided due to client policy)',
        projectDesc: 'School management software for administration,teacher,student',
        tags: ['CodeIgniter','Javascript','Jquery','Ajax', 'HTML', 'CSS','Bootstrap'],
        code: '',
        demo: '',
        image: Harvard
    },
    {
        id: 5,
        projectName: 'Onpsych',
        projectDesc: 'onPsych psychologists work across Australia and are Medicare and NDIS registered.',
        tags: ['WordPress'],
        code: 'https://onpsych.com.au/',
        demo: 'https://onpsych.com.au/',
        image: onPsych
    },
    /*{
        id: 6,
        projectName: 'Compnet',
        projectDesc: 'Compnet is computer repair center is a perfect place for dropping off PCs and laptops in need of repair.',
        tags: ['HTML', 'CSS'],
        code: 'https://compnet.uk/',
        demo: 'https://compnet.uk/',
        image: compnet
    },*/
    {
        id: 6,
        projectName: 'Peterlionsford',
        projectDesc: 'A portfolio website for a person who is a writer, musician, and filmmaker',
        tags: ['PHP','HTML', 'CSS','Javascript'],
        code: 'https://peterlionsford.com/',
        demo: 'https://peterlionsford.com/',
        image: peterlionsford
    },
    {
        id: 7,
        projectName: 'Jinji Golf',
        projectDesc: 'Jinji Golf International Community, offer golf lessons in different golf ranges around Tokyo, provides a fun and friendly environment for learning and playing golf for forginers living in japan .',
        tags: ['wix'],
        code: 'https://www.jinjigolf.org/',
        demo: 'https://www.jinjigolf.org/',
        image: jinji
    },
    {
        id: 8,
        projectName: 'Hyhfusa',
        projectDesc: 'Huayihefeng (HYHF) Chemical Specialties (Zibo) Co., Ltd is a high-tech fine chemical enterprise located in Zibo, Shandong Province. Since 2003',
        tags: ['WordPress'],
        code: 'https://www.hyhfusa.com/',
        demo: 'https://www.hyhfusa.com/',
        image: Hyhfusa
    },
    {
        id: 9,
        projectName: 'Aurora Hardwood',
        projectDesc: 'Aurora Hardwood is built on a solid foundation of trust and commitment to excellence.',
        tags: ['WordPress'],
        code: 'https://development.aurorahardwood.com/',
        demo: 'https://development.aurorahardwood.com/',
        image: aurorahardwood
    },
    {
        id: 10,
        projectName: 'Credit For Retention',
        projectDesc: 'Credit For Retention',
        tags: ['WordPress'],
        code: 'https://creditforretention.com/',
        demo: 'https://creditforretention.com/',
        image: creditforretention
    },
    /*{
        id: 11,
        projectName: 'Quote n Save',
        projectDesc: 'The consumer marketplace that connects you with expert businesses offering competitive quotes. ',
        tags: ['WordPress'],
        code: 'https://quotensave.com/',
        demo: 'https://quotensave.com/',
        image: quotensave
    },*/
    {
        id: 11,
        projectName: 'Hume',
        projectDesc: 'A website is build to sell  ethical beauty products and brands',
        tags: ['Laravel','VUE JS','PHP','HTML', 'CSS','Javascript'],
        code: 'https://myhume.com/',
        demo: 'https://myhume.com/',
        image: hume
    },
    {
        id: 11,
        projectName: 'Artikmed',
        projectDesc: 'Artik Medical Supply provides high-quality, long-lasting medical supplies.',
        tags: ['WordPress'],
        code: 'https://artikmed.com/',
        demo: 'https://artikmed.com/',
        image: artik
    },
    {
        id: 11,
        projectName: 'VC Artizia',
        projectDesc: 'Revolutionizing the NFT Marketplace with AI-Powered Creativity',
        tags: ['WordPress'],
        code: 'https://vc.artizia.io/',
        demo: 'https://vc.artizia.io/',
        image: artizia
    },
    {
        id: 12,
        projectName: 'Kaypros',
        projectDesc: 'Premium Home Services for commercial and residential customers in the Greater Lexington and Columbia areas ',
        tags: ['WordPress'],
        code: 'https://kaypros.com/',
        demo: 'https://kaypros.com/',
        image: kaypros
    },
    {
        id: 13,
        projectName: 'Kay Heating Cooling',
        projectDesc: 'Experience Comfort, Efficiency & Reliability with Kay HVAC Services for residential and commercial customers across the Midlands.',
        tags: ['WordPress'],
        code: 'https://kayheatingcooling.com/',
        demo: 'https://kayheatingcooling.com/',
        image: kayheatingcooling
    },
    {
        id: 14,
        projectName: 'Highway360',
        projectDesc: 'Highway 360 is a countrywide sophisticated transportation service provider company, which conveys you to your desired destination anytime, safely and securely at the lowest possible cost.',
        tags: ['Laravel','Javascript','Jquery','Ajax', 'HTML', 'CSS','Bootstrap','Flutter'],
        code: 'https://highway360.com.bd/',
        demo: 'https://highway360.com.bd/',
        image: highway
    },
    {
        id: 15,
        projectName: 'Onpsych Ndis',
        projectDesc: 'OnPsych is an NDIS registered provider, we work collaboratively with people who have disabilities,their families,and broader supports to holistically support each individual.',
        tags: ['HTML', 'CSS','Bootstrap','Javascript','Jquery','Ajax'],
        code: 'https://onpsychndis.com.au/',
        demo: 'https://onpsychndis.com.au/',
        image: onpyschNdis
    },
    {
        id: 16,
        projectName: 'Onpsych Advocacy',
        projectDesc: 'Onpsych Advocacy',
        tags: ['HTML', 'CSS','Bootstrap','Javascript','Jquery','Ajax'],
        code: 'https://onpsychadvocacy.com.au/',
        demo: 'https://onpsychadvocacy.com.au/',
        image: onPsych
    },
    {
        id: 17,
        projectName: 'Onpsych Ndis CRM',
        projectDesc: 'OnPsych is an NDIS registered provider, we work collaboratively with people who have disabilities,their families,and broader supports to holistically support each individual.',
        tags: ['Wordpress'],
        code: 'https://crm.onpsychndis.com.au/',
        demo: 'https://crm.onpsychndis.com.au/',
        image: onpyschNdis
    },
    {
        id: 18,
        projectName: 'Onpsych Advocacy',
        projectDesc: 'Onpsych Advocacy',
        tags: ['HTML', 'CSS','Bootstrap','Javascript','Jquery','Ajax'],
        code: 'https://onpsychadvocacy.com.au/',
        demo: 'https://onpsychadvocacy.com.au/',
        image: onPsych
    },
    {
        id: 19,
        projectName: 'Onpsych Chaplaincy',
        projectDesc: 'At onPsych Chaplaincy, we protect the mental and emotional wellbeing of students with our chaplain service.  ',
        tags: ['Wordpress'],
        code: 'https://onpsychchaplaincy.com.au/',
        demo: 'https://onpsychchaplaincy.com.au/',
        image: onPsych
    },
    {
        id: 20,
        projectName: 'Caregiver Institute of Bangladesh',
        projectDesc: 'To establish the caregiver profession in Bangladesh like the development countries of the world.',
        tags: ['Laravel','Javascript','Jquery','Ajax', 'HTML', 'CSS','Bootstrap'],
        code: 'https://cib.edu.bd/',
        demo: 'https://cib.edu.bd/',
        image: cib
    },
    /*{
        id: 21,
        projectName: 'Hasan London',
        projectDesc: 'This project aims to build an single vendor E-commerce system',
        tags: ['Laravel','Javascript','Jquery','Ajax', 'HTML', 'CSS','Bootstrap'],
        code: 'http://hasanlondon.uk/',
        demo: 'http://hasanlondon.uk/',
        image: five
    },*/
    {
        id: 22,
        projectName: 'ACJC',
        projectDesc: 'Australian Corporate Jet Centres is a Civil Aviation Safety Authority (CASA) licenced International Aircraft Charter company',
        tags: ['HTML', 'CSS','Bootstrap','Javascript','Jquery','Ajax'],
        code: 'http://www.acjcentres.com.au/',
        demo: 'http://www.acjcentres.com.au/',
        image: acjc
    },
    {
        id: 23,
        projectName: 'Aust IT',
        projectDesc: 'AUS IT Solutions is a business information solution company.',
        tags: ['PHP','HTML', 'CSS','Bootstrap','Javascript','Jquery','Ajax'],
        code: 'http://austit.com.au/',
        demo: 'http://austit.com.au/',
        image: austit
    },
    {
        id: 24,
        projectName: 'Prime home USA',
        projectDesc: 'Prime Home USA aims to enhance peoples lifestyles. Starting with what is close to everyone-home sweet home.',
        tags: ['Wordpress'],
        code: 'https://primehomeusa.com/',
        demo: 'https://primehomeusa.com/',
        image: phu
    },
    {
        id: 25,
        projectName: 'Dnicoca Dhaka',
        projectDesc: 'DNICO, Chartered Accountants is a Partnership Firm and established in 1991 with the permission of or taking Practicing Certificate from the Institute of Chartered Accountants of Bangladesh.',
        tags: ['Laravel','Javascript','Jquery','Ajax', 'HTML', 'CSS','Bootstrap'],
        code: 'https://dnicocadhaka.com/',
        demo: 'https://dnicocadhaka.com/',
        image: dni
    },
    {
        id: 26,
        projectName: 'Solution By Ray',
        projectDesc: 'To stand out in today’s online ecosystem a company needs an awe-inspiring brand. ',
        tags: ['PHP','HTML', 'CSS','Bootstrap','Javascript','Jquery','Ajax'],
        code: 'https://solutionbyray.com/',
        demo: 'https://solutionbyray.com/',
        image: solution
    },
    {
        id: 27,
        projectName: 'The SBR',
        projectDesc: 'The SBR adds the touch of creativity that your brand needs to complement the growth of your company.',
        tags: ['Wordpress'],
        code: 'https://www.thesbr.co/',
        demo: 'https://www.thesbr.co/',
        image: thesbr
    },
    {
        id: 28,
        projectName: 'On Deemand',
        projectDesc: 'Smart digital solutions',
        tags: ['Wordpress'],
        code: 'https://ondeemand.com/',
        demo: 'https://ondeemand.com/',
        image: ondeemand
    },
    /*{
        id: 29,
        projectName: 'Ain e sheba',
        projectDesc: 'Smart digital solutions',
        tags: ['Wordpress'],
        code: 'https://ainesheba.com/',
        demo: 'https://ainesheba.com/',
        image: ainesheba
    }
    */
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/