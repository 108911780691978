/* eslint-disable */
import girl1 from '../assets/svg/testimonials/girl1.svg'
import girl2 from '../assets/svg/testimonials/girl2.svg'
import girl3 from '../assets/svg/testimonials/girl3.svg'
import boy1 from '../assets/svg/testimonials/boy1.svg'
import boy2 from '../assets/svg/testimonials/boy2.svg'
import boy3 from '../assets/svg/testimonials/boy3.svg'



export const testimonialsData = [
    {
        id: 1,
        name: 'Tunsick',
        title: 'CEO',
        text: "Saidur is a pleasure to work with, He is extremely helpful and always provides a great service at such a really good cost, thank you once again.",
        image: boy1
    },
    {
        id: 2,
        name: 'Kenneth Zhu',
        title: 'USA (CEO, Prime Home USA)',
        text: "Saidur covers basically everything that I need for my business. He’s the guy that I can trust and share my ideas.",
        image: boy2
    },
    {
        id: 3,
        name: 'Mostofa kamal',
        title: 'CEO of Compnet.com',
        text: "I always go back to saidur when I’m out of my depth and he’s never failed to deliver what I ask for. Smart, trustworthy and professional.",
        image: boy3
    }
]

