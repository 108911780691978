export const experienceData = [
    {
        id: 1,
        company: 'Infobiz Software Limited',
        jobtitle: 'Software Engineer',
        startYear: 'May 2019',
        endYear: 'July 2022'
    },{
        id: 2,
        company: 'Holister Private Limited',
        jobtitle: 'Software Engineer',
        startYear: 'August 2022',
        endYear: 'Present'
    },
    // {
    //     id: 2,
    //     company: 'Holister Private Limited',
    //     jobtitle: 'Software Engineer',
    //     startYear: '2021',
    //     endYear: 'Present'
    // },

]