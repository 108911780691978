export const educationData = [
    {
        id: 1,
        institution: 'Bangladesh Bank High School',
        course: 'Secondary School Certificate',
        startYear: '2000',
        endYear: '2010'
    },
    {
        id: 2,
        institution: 'Somerset School of Science',
        course: 'Higher Secondary School Certificate',
        startYear: '2010',
        endYear: '2012'
    },
    {
        id: 3,
        institution: 'Jashore University of Science and Technology',
        course: 'Bachelor of Science Honours',
        startYear: '2014',
        endYear: '2019'
    },
]